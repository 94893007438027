import Img1 from "../img/products/1.jpg";
import Img2 from "../img/products/2.jpg";
import Img3 from "../img/products/3.jpg";
import Img4 from "../img/products/4.jpg";
// import Img5 from "../img/products/5.jpeg";
import Img6 from "../img/products/6.jpg";
import Img7 from "../img/products/7.jpeg";
import Img8 from "../img/products/8.jpeg";
import Img9 from "../img/products/9.jpeg";
import Img10 from "../img/products/10.jpg";
import Img11 from "../img/products/11.jpg";
import Img12 from "../img/products/12.jpg";
// import Img13 from "../img/products/13.jpeg";
import Img14 from "../img/products/14.jpeg";
import Img15 from "../img/products/15.jpeg";
import Img16 from "../img/products/16.jpg";
import PasawayUniversity1 from "../img/products/otherProducts/PasawayUniversityCrewneck2.jpeg";
import PasawayUniversity2 from "../img/products/otherProducts/PasawayUniversityIvory2.jpg";
import FirstKulit1 from "../img/products/otherProducts/FirstKulitHoodie1.jpeg";
import FirstKulit2 from "../img/products/otherProducts/FirstKulitHoodie2.jpeg";
import Kamao1 from "../img/products/otherProducts/Kamao1.jpg";
import Kamao2 from "../img/products/otherProducts/Kamao2.jpg";
// import KoreanShirt1 from "../img/products/otherProducts/KulitKoreanShirt1.jpeg";
// import KoreanShirt2 from "../img/products/otherProducts/KulitKoreanShirt2.jpeg";
// import KoreanHoodie1 from "../img/products/otherProducts/KulitKoreanHoodie1.jpeg";
// import KoreanHoodie2 from "../img/products/otherProducts/KulitKoreanHoodie2.jpeg";
// import Barangay1 from "../img/products/otherProducts/BarangayKulitCrewneck1.jpeg";
// import Barangay2 from "../img/products/otherProducts/BarangayKulitCrewneck2.jpeg";
import KulitTee1 from "../img/products/otherProducts/KulitTee1.jpg";
import KulitTee2 from "../img/products/otherProducts/KulitTee2.jpeg";
import PasawaySticker1 from "../img/products/otherProducts/PasawaySticker1.jpeg";
import PasawaySticker2 from "../img/products/otherProducts/PasawaySticker2.jpeg";
import KulitCar1 from "../img/products/otherProducts/KulitCar1.jpeg";
import KulitCar2 from "../img/products/otherProducts/KulitCar2.jpeg";
import BayaniPoster1 from "../img/products/otherProducts/BayaniPoster1.jpeg";
import BayaniPoster2 from "../img/products/otherProducts/BayaniPoster2.jpeg";
import PasawayUniversityGray from "../img/products/otherProducts/PasawayUniversityGray.jpg";
import PasawayUniversityBlack from "../img/products/otherProducts/PasawayUniversityBlack.jpg";
import PasawayUniversityWhite from "../img/products/otherProducts/PasawayUniversityWhite.jpg";
import KamaoChambray from "../img/products/otherProducts/KamaoChambray.jpg";
import KamaoBlack from "../img/products/otherProducts/KamaoBlack.jpg";
// import KoreanIvory from "../img/products/otherProducts/KoreanIvory.jpeg";
// import KoreanGranite from "../img/products/otherProducts/KoreanGranite.jpeg";
import KulitTeeIvory from "../img/products/otherProducts/KulitIvory.jpg";
import KulitTeeBlack from "../img/products/otherProducts/KulitBlack.jpg";
import PasawayUniversityGray1 from "../img/products/otherProducts/PasawayUniversityGray1.jpeg";
import PasawayUniversityGray2 from "../img/products/otherProducts/PasawayUniversityGray2.jpg";
import PasawayUniversityBlack1 from "../img/products/otherProducts/PasawayUniversityBlack1.jpeg";
import PasawayUniversityBlack2 from "../img/products/otherProducts/PasawayUniversityBlack2.jpg";
import PasawayUniversityWhite1 from "../img/products/otherProducts/PasawayUniversityWhite1.jpeg";
import PasawayUniversityWhite2 from "../img/products/otherProducts/PasawayUniversityWhite2.jpg";
import PasawayUniversityGreen from "../img/products/otherProducts/PasawayUniversityGreen.jpg";
import PasawayUniversityGreen1 from "../img/products/otherProducts/PasawayUniversityGreen1.jpg";
import PasawayUniversityGreen2 from "../img/products/otherProducts/PasawayUniversityGreen2.jpg";
import PasawayUniversityNavyBlue from "../img/products/otherProducts/PasawayUniversityNavyBlue.jpg";
import PasawayUniversityNavyBlue1 from "../img/products/otherProducts/PasawayUniversityNavyBlue1.jpg";
import PasawayUniversityNavyBlue2 from "../img/products/otherProducts/PasawayUniversityNavyBlue2.jpg";
import PasawayUniversityRed from "../img/products/otherProducts/PasawayUniversityRed.jpg";
import PasawayUniversityRed1 from "../img/products/otherProducts/PasawayUniversityRed1.jpg";
import PasawayUniversityRed2 from "../img/products/otherProducts/PasawayUniversityRed2.jpg";
import KamaoChambray1 from "../img/products/otherProducts/KamaoChambray1.jpg";
import KamaoChambray2 from "../img/products/otherProducts/KamaoChambray2.jpg";
import KamaoBlack1 from "../img/products/otherProducts/KamaoBlack1.jpg";
import KamaoBlack2 from "../img/products/otherProducts/KamaoBlack2.jpg";
// import KoreanGranite1 from "../img/products/otherProducts/KoreanTeeGranite1.jpeg";
// import KoreanGranite2 from "../img/products/otherProducts/KoreanTeeGranite2.jpeg";
// import KoreanIvory1 from "../img/products/otherProducts/KoreanTeeIvory1.jpeg";
// import KoreanIvory2 from "../img/products/otherProducts/KoreanTeeIvory2.jpeg";
import KulitTeeIvory1 from "../img/products/otherProducts/KulitIvory1.jpg";
import KulitTeeIvory2 from "../img/products/otherProducts/KulitIvory2.jpg";
import KulitTeeBlack1 from "../img/products/otherProducts/KulitBlack1.jpeg";
import KulitTeeBlack2 from "../img/products/otherProducts/KulitBlack2.jpg";
import PinoyAkoHoodie1 from "../img/products/otherProducts/PinoyAkoHoodie1.jpg";
import PinoyAkoHoodie2 from "../img/products/otherProducts/PinoyAkoHoodie2.jpg";
import PinayAkoHoodie1 from "../img/products/otherProducts/PinayAkoHoodie1.jpg";
import PinayAkoHoodie2 from "../img/products/otherProducts/PinayAkoHoodie2.jpg";
import PinoyAkoJacketNavy from "../img/products/otherProducts/PinoyAkoJacketNavy.jpeg";
import PinoyAkoJacketBlack1 from "../img/products/otherProducts/PinoyAkoJacketBlack1.jpeg";
import PinoyAkoJacketBlack2 from "../img/products/otherProducts/PinoyAkoJacketBlack2.jpeg";
import PinoyAkoJacketGray1 from "../img/products/otherProducts/PinoyAkoJacketGray1.jpeg";
import PinoyAkoJacketGray2 from "../img/products/otherProducts/PinoyAkoJacketGray2.jpeg";
import PinayAkoJacketBlack from "../img/products/otherProducts/PinayAkoJacketBlack.jpeg";
import PinayAkoJacketNavy1 from "../img/products/otherProducts/PinayAkoJacketNavy1.jpeg";
import PinayAkoJacketNavy2 from "../img/products/otherProducts/PinayAkoJacketNavy2.jpeg";
import PinayAkoJacketGray1 from "../img/products/otherProducts/PinayAkoJacketGray1.jpeg";
import PinayAkoJacketGray2 from "../img/products/otherProducts/PinayAkoJacketGray2.jpeg";
// import PhilippinesSocks from "../img/products/otherProducts/PhilippinesSocks.jpeg";
import Marupok1 from "../img/products/otherProducts/Marupok1.jpeg";
import Marupok2 from "../img/products/otherProducts/Marupok2.jpg";
import ShotPuno1 from "../img/products/otherProducts/ShotPuno1.jpg";
import ShotPuno2 from "../img/products/otherProducts/ShotPuno2.jpg";
import ShotPunoGray from "../img/products/otherProducts/ShotPunoGray.jpg";
import ShotPunoGray1 from "../img/products/otherProducts/ShotPunoGray1.jpg";
import ShotPunoGray2 from "../img/products/otherProducts/ShotPunoGray2.jpg";
import ShotPunoGreen from "../img/products/otherProducts/ShotPunoGreen.jpg";
import ShotPunoGreen1 from "../img/products/otherProducts/ShotPunoGreen1.jpg";
import ShotPunoGreen2 from "../img/products/otherProducts/ShotPunoGreen2.jpg";
import MaligayangPasko1 from "../img/products/otherProducts/MaligayangPasko1.jpg";
import MaligayangPasko2 from "../img/products/otherProducts/MaligayangPasko2.jpg";
import MaligayangPaskoWhite from "../img/products/otherProducts/MaligayangPaskoWhite.jpg";
import MaligayangPaskoWhite1 from "../img/products/otherProducts/MaligayangPaskoWhite1.jpg";
import MaligayangPaskoWhite2 from "../img/products/otherProducts/MaligayangPaskoWhite2.jpg";
import MaligayangPaskoGreen from "../img/products/otherProducts/MaligayangPaskoGreen.jpg";
import MaligayangPaskoGreen1 from "../img/products/otherProducts/MaligayangPaskoGreen1.jpg";
import MaligayangPaskoGreen2 from "../img/products/otherProducts/MaligayangPaskoGreen2.jpg";

export const items = [
  {
    id: 1,
    category: "tops",
    img: Img1, // Default image
    description: "Pasaway University Sweatshirt",
    price: 29.99,
    shippingCost: 8.49,
    additionalShippingCost: 2.09,
    otherImgs: [PasawayUniversity1, PasawayUniversity2],
    specs: "This Crewneck sweatshirt is designed to reference a certain characteristic that Pinoy’s have. Made with cotton and polyester to ensure your comfort. Perfect for cool weather.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Ivory", stripePriceId: "price_1QMjeALO5J7ORzPKu7L5Oj7S", img: Img1, additionalImgs: [Img1, PasawayUniversity1, PasawayUniversity2] },
      { color: "Gray", stripePriceId: "price_1QMje3LO5J7ORzPKsBWDjmbp", img: PasawayUniversityGray, additionalImgs: [PasawayUniversityGray, PasawayUniversityGray1, PasawayUniversityGray2] },
      { color: "Black", stripePriceId: "price_1QMje1LO5J7ORzPKTTbU6ZP8", img: PasawayUniversityBlack, additionalImgs: [PasawayUniversityBlack, PasawayUniversityBlack1, PasawayUniversityBlack2] },
      { color: "White", stripePriceId: "price_1QMjdzLO5J7ORzPKLAtqgGKD", img: PasawayUniversityWhite, additionalImgs: [PasawayUniversityWhite, PasawayUniversityWhite1, PasawayUniversityWhite2] },
      { color: "Navy Blue", stripePriceId: "price_1QMjdxLO5J7ORzPKn9u68XlE", img: PasawayUniversityNavyBlue, additionalImgs: [PasawayUniversityNavyBlue, PasawayUniversityNavyBlue1, PasawayUniversityNavyBlue2] },
      { color: "Red", stripePriceId: "price_1QMjdvLO5J7ORzPKUZaol1Sn", img: PasawayUniversityRed, additionalImgs: [PasawayUniversityRed, PasawayUniversityRed1, PasawayUniversityRed2] },
      { color: "Green", stripePriceId: "price_1QMjduLO5J7ORzPKwpWywbTl", img: PasawayUniversityGreen, additionalImgs: [PasawayUniversityGreen, PasawayUniversityGreen1, PasawayUniversityGreen2] }
    ],
    rating: 0,
    label: "Best Seller",
  },  
  {
    id: 3,
    category: "tops",
    img: Img10,
    description: "Shot Puno Sweatshirt",
    price: 29.99,
    shippingCost: 4.75, // Base shipping cost
    additionalShippingCost: 2.40, // Reduced cost for each additional item
    otherImgs: [ShotPuno1, ShotPuno2],
    specs:
      "Shot puno!!! This crewneck sweatshirt is made with cotton and polyester to ensure your comfort, perfect for cool weather.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "White", stripePriceId: "price_1QUKHALO5J7ORzPKI1aJIvCm", img: Img10, additionalImgs: [Img10, ShotPuno1, ShotPuno2] },
      { color: "Gray", stripePriceId: "price_1QUKHvLO5J7ORzPKvWswPl1C", img: ShotPunoGray, additionalImgs: [ShotPunoGray, ShotPunoGray1, ShotPunoGray2] },
      { color: "Green", stripePriceId: "price_1QUKIHLO5J7ORzPKXpDLUgAp", img: ShotPunoGreen, additionalImgs: [ShotPunoGreen, ShotPunoGreen1, ShotPunoGreen2] },
    ],
    rating: 0,
    label: "",
  },
  // {
  //   id: 2,
  //   category: "tops",
  //   img: Img4,
  //   description: "Maligayang Pasko Sweatshirt",
  //   price: 29.99,
  //   shippingCost: 4.75, // Base shipping cost
  //   additionalShippingCost: 2.40, // Reduced cost for each additional item
  //   otherImgs: [Marupok1, Marupok2],
  //   specs:
  //     "This crewneck sweatshirt is designed to represent Christmas in a Filipino Culture. Made with cotton and polyester to ensure your comfort, Perfect for cool weather.",
  //   texture: "50% Cotton / 50% Polyester",
  //   sizes: ["Small", "Medium", "Large", "Extra Large"],
  //   colors: [
  //     { color: "Red", stripePriceId: "price_1QUKMcLO5J7ORzPK9Ezjn2Ub", img: Img4, additionalImgs: [Img4, MaligayangPasko1, MaligayangPasko2] },
  //     { color: "Green", stripePriceId: "price_1QUKNLLO5J7ORzPKbqVQaMvq", img: MaligayangPaskoGreen, additionalImgs: [MaligayangPaskoGreen, MaligayangPaskoGreen1, MaligayangPaskoGreen2] },
  //     { color: "White", stripePriceId: "price_1QUKLJLO5J7ORzPKi2CrgCze", img: MaligayangPaskoWhite, additionalImgs: [MaligayangPaskoWhite, MaligayangPaskoWhite1, MaligayangPaskoWhite2] },
  //   ],
  //   rating: 0,
  //   label: "Limited Edition",
  // },
  {
    id: 13,
    category: "tops",
    img: Img2,
    description: "Classic Kulit Hoodie",
    price: 34.99,
    shippingCost: 8.49,
    additionalShippingCost: 2.09,
    otherImgs: [FirstKulit1, FirstKulit2],
    specs: "A classic styled hoodie representing the Kulit brand. Made with a thick blend of cotton and polyester, feels plush, soft and warm, a perfect choice for any cold day.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "White", stripePriceId: 'price_1QMje5LO5J7ORzPK6xfNctjQ', img: Img2, additionalImgs: [Img2, FirstKulit2, FirstKulit1] },
    ],
    rating: 0,
    // label: "Best Seller",
  },
  {
    id: 14,
    category: "tops",
    img: Img3,
    description: "Kamao T-Shirt",
    price: 29.99,
    shippingCost: 4.75, // Base shipping cost
    additionalShippingCost: 2.40, // Reduced cost for each additional item
    otherImgs: [Kamao1, Kamao2],
    specs:
      "This Tee is designed with a 2d pixelated style and is inspired by our pambansang kamao to represent the bravery and dedication of Filipinos. Made with 100% ringspun cotton to make it an excellent daily choice.",
    texture: "100% Cotton", 
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Ivory", stripePriceId: "price_1QMjdpLO5J7ORzPKLtNjvGRe", img: Img3, additionalImgs: [Img3, Kamao2, Kamao1] },
      { color: "Chambray", stripePriceId: "price_1QMjdnLO5J7ORzPKx4Pz0VZF", img: KamaoChambray, additionalImgs: [KamaoChambray, KamaoChambray1, KamaoChambray2] },
      { color: "Black", stripePriceId: "price_1QMjdiLO5J7ORzPKaDdMXXbC", img: KamaoBlack, additionalImgs: [KamaoBlack, KamaoBlack1, KamaoBlack2] },
    ],
    rating: 0,
  },
  // {
  //   id: 10,
  //   category: "tops",
  //   img: Img4,
  //   description: "Korean Kulit T-Shirt",
  //   price: 19.99,
  //   shippingCost: 4.75, // Base shipping cost
  //   additionalShippingCost: 2.40, // Reduced cost for each additional item
  //   otherImgs: [KoreanShirt1, KoreanShirt2],
  //   specs:
  //     "A simple styled tee representing the Kulit brand in Korean. Made with 100% wingspun cotton to make it an excellent daily choice.",
  //   texture: "100% Cotton",
  //   sizes: ["Small", "Medium", "Large", "Extra Large"],
  //   colors: [
  //     { color: "White", img: Img4, additionalImgs: [KoreanShirt1, KoreanShirt2]},
  //     { color: "Granite", img: KoreanGranite, additionalImgs: [KoreanGranite, KoreanGranite1, KoreanGranite2] },
  //     { color: "Ivory", img: KoreanIvory, additionalImgs: [KoreanIvory, KoreanIvory1, KoreanIvory2] },
  //   ],
  //   rating: 0,
  // },
  {
    id: 4,
    category: "tops",
    img: Img11,
    description: "Pinoy Ako Hoodie",
    price: 34.99,
    shippingCost: 8.49, // Base shipping cost
    additionalShippingCost: 2.09, // Reduced cost for each additional item
    otherImgs: [PinoyAkoHoodie1, PinoyAkoHoodie2],
    specs:
      "A Hoodie representing the Philippines, designed with the colors and other elements on the Philippine flag. Made with a thick blend of cotton and polyester, feels plush, soft and warm, a perfect choice for any cold day.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Black", stripePriceId: "price_1QMjdfLO5J7ORzPKihKoyjdT", img: Img11, additionalImgs: [Img11, PinoyAkoHoodie1, PinoyAkoHoodie2] },
    ],
    rating: 4.5,
    reviews: [
      {
        id: 1,
        reviewerName: "Miguel Beltran",
        comment: "I love it! The overall fitting and material is comfortable and snug. It also doesn’t wash away after one wash. Definitely gonna get some more in the future!.",
        date: "11/1/2024",
        rating: 5
      },
      // {
      //   id: 2,
      //   reviewerName: "Maria Clara",
      //   comment: "Proud to wear something that represents my heritage. Great quality too!",
      //   rating: 4.5
      // },
      // {
      //   id: 3,
      //   reviewerName: "Andres Bonifacio",
      //   comment: "Highly recommend! Keeps me warm and looks fantastic.",
      //   rating: 5
      // }
    ]
  },  
  {
    id: 5,
    category: "tops",
    img: Img6,
    description: "Kulit T-Shirt",
    price: 24.99,
    shippingCost: 4.75, // Base shipping cost
    additionalShippingCost: 2.40, // Reduced cost for each additional item
    otherImgs: [KulitTee1, KulitTee2],
    specs:
      "A classic styled tee representing the Kulit brand. Made with 100% wingspun cotton to make it an excellent daily choice.",
    texture: "100% Cotton",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "White", stripePriceId: "price_1QMjdcLO5J7ORzPKFyW9nfxN", img: Img6, additionalImgs: [Img6, KulitTee2, KulitTee1]},
      { color: "Ivory", stripePriceId: "price_1QMjdaLO5J7ORzPKLo8Wd1dI", img: KulitTeeIvory, additionalImgs: [KulitTeeIvory, KulitTeeIvory1, KulitTeeIvory2] },
      { color: "Black", stripePriceId: "price_1QMjdWLO5J7ORzPKbkKXnfRs", img: KulitTeeBlack, additionalImgs: [KulitTeeBlack, KulitTeeBlack1, KulitTeeBlack2] },
    ],
    rating: 0,
  },
  {
    id: 6,
    category: "tops",
    img: Img12,
    description: "Pinay Ako Hoodie",
    price: 34.99,
    shippingCost: 8.49, // Base shipping cost
    additionalShippingCost: 2.09, // Reduced cost for each additional item
    otherImgs: [PinayAkoHoodie1, PinayAkoHoodie2],
    specs:
      "A Hoodie representing the Philippines, designed with the colors and other elements on the Philippine flag. Made with a thick blend of cotton and polyester, feels plush, soft and warm, a perfect choice for any cold day. ",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Black", stripePriceId: "price_1QMjdULO5J7ORzPKsAKqu9jz", img: Img12, additionalImgs: [Img12, PinayAkoHoodie1, PinayAkoHoodie2] },
    ],
    rating: 0,
  },
  {
    id: 7,
    category: "tops",
    img: Img14,
    description: "Pinoy Ako Jacket",
    price: 44.99,
    shippingCost: 8.49, // Base shipping cost
    additionalShippingCost: 2.09, // Reduced cost for each additional item
    otherImgs: [PinoyAkoJacketNavy],
    specs:
      "A full comfy zip-up hoodie, featuring air-jet spun yarn for a softer fleece with reduced pilling. Designed with colors and other elements on the Philippine flag to represent the Philippines. Made with 50% cotton and 50% polyester, once put on, it will be impossible to take off.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Navy Blue", stripePriceId: "price_1QMjdGLO5J7ORzPK8AmWJDUw", img: Img14, additionalImgs: [Img14, PinoyAkoJacketNavy] },
      { color: "Gray", stripePriceId: "price_1QMjcvLO5J7ORzPKYZO3IWEY", img: PinoyAkoJacketGray1, additionalImgs: [PinoyAkoJacketGray1, PinoyAkoJacketGray2] },
      { color: "Black", stripePriceId: "price_1QMrtgLO5J7ORzPKO8LUUkVa", img: PinoyAkoJacketBlack1, additionalImgs: [PinoyAkoJacketBlack1, PinoyAkoJacketBlack2] },
    ],
    rating: 0,
  },
  {
    id: 8,
    category: "tops",
    img: Img15,
    description: "Pinay Ako Jacket",
    price: 44.99,
    shippingCost: 8.49, // Base shipping cost
    additionalShippingCost: 2.09, // Reduced cost for each additional item
    otherImgs: [PinayAkoHoodie1, PinayAkoHoodie2],
    specs:
      "A full comfy zip-up hoodie, featuring air-jet spun yarn for a softer fleece with reduced pilling. Designed with colors and other elements on the Philippine flag to represent the Philippines. Made with 50% cotton and 50% polyester, once put on, it will be impossible to take off.",
    texture: "50% Cotton / 50% Polyester",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Black", stripePriceId: "price_1QMjcmLO5J7ORzPKPjlSXpx5", img: Img15, additionalImgs: [Img15, PinayAkoJacketBlack] },
      { color: "Navy Blue", stripePriceId: "price_1QMjcrLO5J7ORzPKcMWYJwzG", img: PinayAkoJacketNavy1, additionalImgs: [PinayAkoJacketNavy1, PinayAkoJacketNavy2] },
      { color: "Gray", stripePriceId: "price_1QMjcoLO5J7ORzPKqj6FFRnW", img: PinayAkoJacketGray1, additionalImgs: [PinayAkoJacketGray1, PinayAkoJacketGray2] },
    ],
    rating: 0,
  },
  {
    id: 9,
    category: "other",
    img: Img7,
    description: "Pasaway Sticker",
    price: 1.99,
    shippingCost: 4.69, // Base shipping cost
    additionalShippingCost: 0.09, // Reduced cost for each additional item
    otherImgs: [PasawaySticker1, PasawaySticker2],
    specs:
      "A decal representing the Pasaway University. Made with a high quality white vinyl, these decals deliver great looks that are water, scratch, and UV-resistant.",
    texture: "White vinyl with a satin finish",
    size: "10cm x 5cm",
    color: "N/A",
    stripePriceId: "price_1QMjckLO5J7ORzPKourFVmbL",
    rating: 0,
  },
  {
    id: 10,
    category: "other",
    img: Img8,
    description: "Kulit Drift Sticker",
    price: 1.99,
    shippingCost: 4.69, // Base shipping cost
    additionalShippingCost: 0.09, // Reduced cost for each additional item
    otherImgs: [KulitCar1, KulitCar2],
    specs:
      "A decal representing a Japanese drift vehicle and is design with a cool blue background. Made with a high quality white vinyl, these decals deliver great looks that are water, scratch, and UV-resistant.",
    texture: "White vinyl with a satin finish",
    size: "10cm x 5cm",
    color: "N/A",
    stripePriceId: "price_1QMjciLO5J7ORzPK5RTj1Q7Y",
    rating: 0,
  },
  {
    id: 11,
    category: "other",
    img: Img9,
    description: "Bayani Poster",
    price: 7.99, // Base price for the smallest size
    shippingCost: 6.79, // Base shipping cost
    additionalShippingCost: 0.99, // Reduced cost for additional items
    prices: { 
      "9 x 11": 7.99, 
      "12 x 18": 14.99, 
      "24 x 36": 24.99 
    },
    otherImgs: [BayaniPoster1, BayaniPoster2],
    specs: "A poster representing a bayani in the Philippines, José Rizal. Made with a modern style poster and a collage of different colored paint splatters in the background. Made with museum grade paper, available in 3 sizes.",
    texture: "Cotton",
    sizes: [
      { size: "9 x 11", stripePriceId: "price_1QMjcfLO5J7ORzPKhVh7bABt" },
      { size: "12 x 18", stripePriceId: "price_1QMjcdLO5J7ORzPKsSqzDcAq" },
      { size: "24 x 36", stripePriceId: "price_1QMjccLO5J7ORzPKQoKe1ali" },
    ],
    rating: 0,
  },  
  // {
  //   id: 9,
  //   category: "tops",
  //   img: Img10,
  //   description: "Korean Kulit Hoodie",
  //   price: 29.99,
  //   shippingCost: 8.49, // Base shipping cost
  //   additionalShippingCost: 2.09, // Reduced cost for each additional item
  //   otherImgs: [KoreanHoodie1, KoreanHoodie2],
  //   specs:
  //     "A simple styled hoodie representing the Kulit brand in Korean. Made with a thick blend of cotton and polyester, feels plush, soft and warm, a perfect choice for any cold day.",
  //   texture: "50% Cotton / 50% Polyester",
  //   sizes: ["Small", "Medium", "Large", "Extra Large"],
  //   colors: [
  //     { color: "Charcoal", img: Img10, additionalImgs: [KoreanHoodie1, KoreanHoodie2] },
  //   ],
  //   rating: 0,
  // },
  // {
  //   id: 12,
  //   category: "accessories",
  //   img: Img13,
  //   description: "Philippines Socks",
  //   price: 9.99,
  //   shippingCost: 8.49, // Base shipping cost
  //   additionalShippingCost: 2.09, // Reduced cost for each additional item
  //   otherImgs: [PhilippinesSocks],
  //   specs:
  //     "A simple styled hoodie representing the Kulit brand in Korean. Made with a thick blend of cotton and polyester, feels plush, soft and warm, a perfect choice for any cold day.",
  //   texture: "50% Cotton / 50% Polyester",
  //   sizes: ["Small", "Medium", "Large", "Extra Large"],
  //   rating: 0,
  // },
  {
    id: 2,
    category: "tops",
    img: Img16,
    description: "Marupok T-Shirt",
    price: 24.99,
    shippingCost: 4.75, // Base shipping cost
    additionalShippingCost: 2.40, // Reduced cost for each additional item
    otherImgs: [Marupok1, Marupok2],
    specs:
      "A comfortable tee designed to reference a personality Filipinos have, Marupok. Made with 100% wingspun cotton to make it an excellent daily choice.",
    texture: "100% Cotton",
    sizes: ["Small", "Medium", "Large", "Extra Large"],
    colors: [
      { color: "Red", stripePriceId: "price_1QMjcYLO5J7ORzPKRsYNFSuL", img: Img16, additionalImgs: [Img16, Marupok1, Marupok2] },
    ],
    rating: 0,
  },
];
